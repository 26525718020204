h1, h2, h3, h4, h5, h6{
  margin: 0;
}

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 0;
  padding-left: 16px;
}
p {
  margin: 0;
}

img {
  margin-right: 10px;
  max-width: 100%;
}

.quill {
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 200px);
}
.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
